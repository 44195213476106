.main-container {
  min-height: calc( 100vh - 70px );
  max-height: calc( 100vh - 75px );
  overflow-y: auto;
}

.date-picker {
	display: flex;
	justify-content: end;
}

.date-picker fieldset {
  border: none;
  border-bottom: 1px solid #bbb7b7;
  border-radius: 0px;
}

.date-picker label {
	left: -12px;
}

.date-picker input {
  padding-top: 12px;
	padding-bottom: 12px;
	font-size: 0.75rem;
	padding-left: 2px;
}

.date-picker-no-padding-left input{
  padding-left: 3px;
}

.date-picker.datepicker-error {
	border-bottom: 1px solid #d32f2f;
}

.datepicker-error label {
	color: #d32f2f;
}

.top-clients #bottom-axis-container {
  visibility: hidden;
}

.folders .tree-view-card .tree-view-card-content{
  padding: 0px 16px;
  padding-bottom: 0px;
}

.tree-view-section{
  height: 31.5rem;
  max-height: 31.5rem;
  overflow-y: auto;
}

.bill-card .bills-detail-vert-dots{
  padding-right: 0px;
}

.menu-container .menu {
  min-height: calc( 100vh - 190px);
  max-height: calc( 100vh - 190px);
}

.menu-container .menu .MuiListItemIcon-root {
	min-width: 36px;
}

.menu-container .menu .menu-item{
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: flex-start;
}

.menu-container .menu .disabled-menu-item{
	opacity: 0.5;
}

.menu-container .menu .MuiListItemText-root span {
	font-size: 0.75rem;
}

.active-users-card {
	width: calc( 100vw - 240px ); 
}

.search-input {
	border: none;
	background-color: transparent;
	resize: none;
	outline: none;
}

.MuiAlert-message {
	white-space: break-spaces;
}